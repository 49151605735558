import React, {useRef, useState, useEffect} from "react";
import "./FadeIntoView.css";

//https://www.selbekk.io/blog/2019/08/how-to-fade-in-content-as-it-scrolls-into-view/

export default function FadeIntoView(props) {
    const [isVisible, setVisible] = useState(true);
    const domRef = useRef();
    // useEffect(() => {
    //   const observer = new IntersectionObserver(entries => {
    //     entries.forEach(entry => setVisible(entry.isIntersecting));
    //   });
    //   observer.observe(domRef.current);
    //   return () => observer.unobserve(domRef.current);
    // }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }