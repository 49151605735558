import React, {useEffect, useState} from "react";
import ImageLinkBlock from "../ImageLinkBlock";
import Slider from "../../libs/Slider";
import FadeIntoView from "../FadeIntoView"
import "./Stories.css";
import "../../css/global.scss";

import arrowLeft from "../../images/arrow-left.svg";
import arrowRight from "../../images/arrow-right.svg";

export default function Stories({slides, children, id}){

    id = id || "stories";

    const [dimensions, setDimensions] = useState({
        height: 0,
        width: 0
    });

    // if(typeof window !== `undefined`){
    //     setDimensions({
    //         height: window.innerHeight,
    //         width: window.innerWidth
    //     })
    // }

    const [viewType, setViewType] = useState("wide");
    
    useEffect(function(){
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }

        if(window.innerWidth<600){
            setViewType("mobile");
            let slideContainer = document.getElementById(id);
            new Slider({
                element: slideContainer,
                strip: slideContainer.querySelector(".story-blocks"),
                slides: slideContainer.querySelectorAll(".slide")
            });
        }

        window.addEventListener('resize', handleResize);

        return _ => {
            window.removeEventListener('resize', handleResize)
        }

    },[])

    return (
        <section id={id} className="stories">
            <article className="standard-content pad">
                {children}
            </article>

            <div className="blocks-container">
                <div className="story-blocks">
                    {
                        slides.map((story, i)=>(
                            <FadeIntoView>
                                <div className="slide" key={i}>
                                    <ImageLinkBlock 
                                        img={story.image} 
                                        to={story.slug} 
                                        label={story.label}
                                        intro={story.intro}
                                    />
                                </div>
                            </FadeIntoView>
                        ))
                    }
                </div>
                
                    <div className="stories-slidecontrol">
                        <div className="arrow previous"><img src={arrowLeft} alt="left arrow"/></div>
                        <div className="progress">
                            <div className="percent"></div>
                        </div>
                        <div className="arrow next"><img src={arrowRight} alt="right arrow"/></div>
                    </div>
                    
            </div>
            
        </section>
    );
}