import React from 'react'
import './ContentAndMastImage.css'

export default function ContentAndMastImage({children, image}){
    return (
        <div className="contentWithImage mast">
            <div className="standard-content">
                {children} 
            </div>
            <div className="hero-scroll" style={{backgroundImage: `url("http://api.vanarts.com:3000/assets/${image}")`}} >

            </div>
        </div>
    )
}