
const Slider = function({element, strip, slides}){
  
    const slider = this;
    slider.element = element;
    slider.strip = strip;
    slider.slides = slides;
    slider.btnPrev = slider.element.querySelector(".previous");
    slider.btnNext = slider.element.querySelector(".next");
    slider.progressBar = slider.element.querySelector(".percent");

    // this will be called on resize. 
    slider.setup = function(){
        // most of these items depend on width and positioning
        slider.percentage = 1/slider.slides.length*100;
        slider.left = 0;
        slider.currentSlide = 1;
        slider.width = slider.element.clientWidth;
        slider.maxLeft = slider.element.clientWidth * (slider.slides.length - 1) * -1;
        slider.strip.width = slider.slides.length * (slider.width + 40);
        slider.strip.style.width = slider.strip.width + "px";
        
        slider.updateSlidesWidth();
        slider.updateProgress();
    }

    slider.updateSlidesWidth = function(){
        slider.slides.forEach(slide=>{
            slide.style.width = slider.width + "px";
        })
    }

    slider.goNext = function(){
        if(slider.left===slider.maxLeft){
            slider.strip.style.left = (slider.maxLeft - 20) + "px";
            setTimeout(function(){
                slider.strip.style.left = slider.maxLeft + "px";
            }, 200)
        } else {
            slider.currentSlide++;
            slider.left = slider.left - slider.width;
            slider.strip.style.left = slider.left + "px";
        }
    }

    slider.goPrevious = function(){
        if(slider.left===0){
            slider.strip.style.left = 20 + "px";
            setTimeout(function(){
                slider.strip.style.left = 0 + "px";
            }, 200)
        } else {
            slider.currentSlide--;
            slider.left = slider.left + slider.width;
            slider.strip.style.left = slider.left + "px";
        }
    }

    slider.updateProgress = function(){
        slider.percentage = slider.currentSlide/slider.slides.length*100;
        slider.progressBar.style.width = slider.percentage + "%";
    }

    slider.init = function(){
        // do the initializing steps
        slider.setup();
    }

    slider.btnPrev.addEventListener("click", function(){
        slider.goPrevious();
        slider.updateProgress();
    })


    slider.btnNext.addEventListener("click", function(){
        slider.goNext();
        slider.updateProgress();
    })

    slider.init();

}

export default Slider;

