import React, {useEffect, useState} from 'react'
import {Link} from "gatsby"
import Layout from "../components/Layout";
import HeroSlideShow from "../components/HeroSlideShow";
import IconList from "../components/IconList";
import FeatureContent from "../components/FeatureContent";
import ContentSeries from "../components/ContentSeries";
import Stories from "../components/Stories";
import ContentAndMastImage from "../components/ContentAndMastImage";


const HomePage = ({pageContext: pagePassedContext}) => {
    console.log(pagePassedContext)
    
    // get the data vars we need from the json data
    // this came from http://api.vanarts.com/api/pages
    //let data = pageContext.content.json_data.data;
    //let navigation = pageContext.flyoutnav;

    const [pageContext, setContext] = useState(pagePassedContext);

    let data = pageContext.content.json_data.data;
    let nav = pageContext.flyoutnav;
    
    let slides = data.slides;
    
    // rename the keys inside the link list
    let programLinks = [];
    data.programLinks.forEach(link=>{
        let modLink =  {name: link.title, slug: link.URL, icon: link.icon};
        programLinks.push(modLink);
    })

    let featuredContentInnovation = data.featureBox;
    let contentBlocks = data.contentBlocks;
    let successStories = data.wallOfSuccess;
    let gradsWork = data.gradsWork;
    let news = data.news;
    
    useEffect(function(){
        fetch("http://api.vanarts.com:3000/pages/"+pageContext.content.id).then(response=>{
            return response.json();
        }).then(function(json){
            setContext(json)
        })
    }, [])


    return (
        <Layout nav={nav}>
            <HeroSlideShow slides={slides}/>
            <section className="row w-extrapad o-visible">
                <div className="introContentElement">
                    <article className="standard-content wide-4_12">
                        <div className="pad">
                            <div dangerouslySetInnerHTML={{__html: data.introContent.introduction}}/>
                            <Link className="btn-outline-cta" to={data.introContent.actionBoxLink}>{data.introContent.actionBoxText}</Link>
                        </div>
                    </article>

                    <IconList className="wide-8_12" links={programLinks} id="programlist"/>
                </div>
            </section>

            {
                featuredContentInnovation.content &&
            
                <FeatureContent caption={featuredContentInnovation.highlightText} cover={featuredContentInnovation.cover}>
                    <div dangerouslySetInnerHTML={{__html: featuredContentInnovation.content}}/>
                </FeatureContent>
            }
            

            {
                contentBlocks.sectionTitle && 
            
                <ContentSeries 
                    title={contentBlocks.sectionTitle}
                    content={contentBlocks}
                    cols="true" 
                />
            }

            { 
                successStories.intro && 
            
                <Stories slides={successStories.stories}>
                    <div dangerouslySetInnerHTML={{__html: successStories.intro}}/>
                </Stories>
            }
            
            {
                gradsWork.content &&
            
                <ContentAndMastImage image={gradsWork.image}>
                     <div dangerouslySetInnerHTML={{__html: gradsWork.content}}/>
                </ContentAndMastImage>
            }


            {
                news &&
                <Stories slides={news.newsBlocks} id="news">
                    <h2>{news.title}</h2>
                </Stories>
            }         

        </Layout>
    )
}

export default HomePage;