
const FadeThroughShow = function({element, speed, slideChangeCB}){
    const slideshow = this;
    
    slideshow.element = document.getElementById(element);
    slideshow.slides = slideshow.element.querySelectorAll(".slide")
    slideshow.stack = [...slideshow.slides]
    slideshow.speed = speed || 10 // seconds
    slideshow.currentSlideShowing = null;
    slideshow.currentSlideNum = 0
    slideshow.slideChangeCB = slideChangeCB

    slideshow.init = function(){
       
        // only start the slide show, if we have more than one slide. 
        if(slideshow.slides.length>0)
        {
            console.log("found slides");
            // convert slides array to slide stack...
            // highest item in stack is showing...  
            
            slideshow.interval = setInterval(function(){
                // get the item at the top of stack..
                 
                if (slideshow.stack.length>0)
                {
                    slideshow.currentSlideShowing = slideshow.stack.pop()
                    slideshow.currentSlideShowing.style.opacity = 0
                    //slideshow.currentSlideShowing.unbind("transitionend")
                
                    /* move the slide counter */
                    slideshow.currentSlideNum++;
                    if(slideshow.currentSlideNum===slideshow.slides.length)
                    {
                        slideshow.currentSlideNum=0
                    }

                    //slideshow.slideChangeCB(slideshow.currentSlideNum)

                    slideshow.currentSlideShowing.addEventListener("transitionend", function(){
                        
                        // reset zindex... 
                        if(slideshow.currentSlideShowing.style.opacity==0)
                        {
                            slideshow.stack.unshift(slideshow.currentSlideShowing)
                            slideshow.resetZIndex();
                        }

                        slideshow.currentSlideShowing.style.opacity = 1
                    })
                } else {
                    console.log("slides in stack: ",slideshow.stack.length)
                }
            }, slideshow.speed*1000); // convert to ms
        }
    }

    slideshow.showSlide = function(slideNum){
        clearInterval(slideshow.interval)
    }

    slideshow.resetZIndex = function(){
        slideshow.stack.forEach((slide, i)=>{
            slide.style.zIndex = i
        })
    }

    slideshow.init();
}

export default FadeThroughShow;