import React, {useEffect} from 'react';
import {Link} from 'gatsby'
import './HeroSlideShow.css';
import FadeThroughShow from '../../libs/FadeThroughShow'

const HeroSlideShow = ({slides}) =>{

    console.log(slides);
    // do this only on first load
    useEffect(function(){
        // trigger the slideshow on load
        new FadeThroughShow({element: "hero", speed: 8, slideChangeCB: ()=>{}});
    }, [])

    return (
        <div id="hero">
            <div className="slides-container">
                {
                    slides.map((slide, i)=>(
                        <div className="slide" style={{"backgroundImage": `url("http://api.vanarts.com:3000/assets/${slide.image}")`}} key={i}>
                            <div className="slide-content">
                                <div className="text">
                                    <h1>{slide.heading}</h1>
                                    <Link to={slide.actionLink} className="slide-cta">{slide.actionText}</Link> 
                                </div>
                                <div className="swiper"></div>   
                            </div>
                         </div>
                    ))
                }
               
            </div>
        </div>
    )
}

export default HeroSlideShow;